<template>
  <NavBar />

  <section class="section px-3">
    <div class="container">

      <!-- <h5>21物件</h5> -->
      <form method="GET" id="Form" action="/estate-century/index">

        <div class="form-group ">
          <label>關鍵字 </label>
          <input type="text" class="form-control input-field" v-model="keyword" name="keyword" placeholder="輸入案名">
        </div>

        <div class="form-group ">
          <label>地址 </label>
          <input type="text" class="form-control input-field" v-model="address" name="address" placeholder="輸入地址">
        </div>

        <div class="form-group ">
          <label>加盟店 </label>
          <input type="text" class="form-control input-field" v-model="store_name" name="store_name"
            placeholder="輸入加盟店名稱">
        </div>

        <div class="form-row" id="twzipcode">
          <div class="col-6">
            <fieldset class="form-group">
              <label>縣市</label>
              <div data-role="county" data-name="county" id="county" data-value='台北市' data-style="custom-select">
              </div>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>行政區</label>
              <div data-role="district" data-name="district" id="district" data-value='不限' data-style="custom-select">
              </div>
            </fieldset>
          </div>
          <div data-role="zipcode" data-value="160" data-style="zipcode-style" style="display:none;"></div>
        </div>

        <div class="form-row">
          <div class="col-6">
            <fieldset class="form-group">
              <label>時間</label>
              <select class="custom-select select-field" name="date_range">
                <option value="">不限</option>
                <option v-for="(date_range, index) in date_ranges" :value="index">
                  {{ date_range }}
                </option>
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>類型</label>
              <select class="custom-select select-field" name="type">
                <option value="">不限</option>
                <option v-for="(type, index) in types" :value="index">
                  {{ type }}
                </option>
              </select>
            </fieldset>
          </div>
        </div>

        <div class="form-row">
          <div class="col-6">
            <fieldset class="form-group">
              <label>狀態</label>
              <select class="custom-select select-field" name="status">
                <option value="">不限</option>
                <option v-for="(status, index) in statuses" :value="index">
                  {{ status }}
                </option>
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>資料區分</label>
              <select class="custom-select select-field" name="d_type">
                <option value="">不限</option>
                <option v-for="(d_type, index) in d_types" :value="index">
                  {{ d_type }}
                </option>
              </select>
            </fieldset>
          </div>
        </div>

        <div class="form-group ">
          <label>總樓層 </label>
          <input type="text" class="form-control input-field" v-model="floor" name="floor" placeholder="總樓層">
        </div>

        <div class="form-group ">
          <label>格局 </label>
          <input type="text" class="form-control input-field" v-model="structure" name="structure" placeholder="輸入格局">
        </div>

        <div class="form-group ">
          <label>屋齡 </label>
          <input type="number" class="form-control input-field" v-model="age" name="age" placeholder="輸入屋齡">
        </div>

        <label>主建物坪數</label><br>
        <div class="form-row form-group">

          <div class="col d-flex">
            <input type="number" class="form-control input-field" v-model="min_floor_space" name="min_floor_space">
            <small class="mt-auto ml-1 text-muted">坪</small>
          </div>
          <div class="col-auto d-flex px-0">
            <div class="my-auto text-muted">～</div>
          </div>
          <div class="col d-flex">
            <input type="number" class="form-control input-field" v-model="max_floor_space" name="max_floor_space">
            <small class="mt-auto ml-1 text-muted">坪</small>
          </div>
        </div>

        <label>總坪數</label><br>
        <div class="form-row form-group">

          <div class="col d-flex">
            <input type="number" class="form-control input-field" v-model="min_total_floor_space"
              name="min_total_floor_space">
            <small class="mt-auto ml-1 text-muted">坪</small>
          </div>
          <div class="col-auto d-flex px-0">
            <div class="my-auto text-muted">～</div>
          </div>
          <div class="col d-flex">
            <input type="number" class="form-control input-field" v-model="max_total_floor_space"
              name="max_total_floor_space">
            <small class="mt-auto ml-1 text-muted">坪</small>
          </div>
        </div>

        <label>總價</label><br>
        <div class="form-row form-group">
          <div class="col d-flex">
            <input type="number" class="form-control input-field" v-model="min_price" name="min_price"
              placeholder="輸入總價">
            <small class="mt-auto ml-1 text-muted text-nowrap">萬元</small>
          </div>
          <div class="col-auto d-flex px-0">
            <div class="my-auto text-muted">～</div>
          </div>
          <div class="col d-flex">
            <input type="number" class="form-control input-field" v-model="max_price" name="max_price"
              placeholder="輸入總價">
            <small class="mt-auto ml-1 text-muted text-nowrap">萬元</small>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-3 pt-3 flex-column">
          <button class="btn btn-primary btn-block" type="submit">確認</button>
          <button class="btn btn-dark btn-block mt-3" id="clear" type="button">清除</button>
        </div>
      </form>

    </div>
  </section>
</template>

<script>
// 引入
import NavBar from '@/components/NavBar.vue';
import collection from '@/assets/data/21century_collection.json';
import dictionary from '@/assets/data/dictionary.json'


// console.log(dictionary.folders.status);

// 縣市選單
import twzipcode from '@/assets/plugins/twzipcode/jquery.twzipcode.min.js';

$(document).ready(function () {
  $('#twzipcode').twzipcode();
  //點擊清除
  $("#clear").click(function () {
    $('.input-field').val('');
    $('.select-field').val('');
    $("[name='district']").val('');
    $("[name='county']").val('台北市');
    $('')
    //$("[name='date_range']").val('三日內');
    //$("[name='d_type']").val('租屋');
  });
});



// 輸出
export default {
  name: 'century',
  components: {
    NavBar,
  }, data() {
    return {
      // 下拉選單外的欄位
      keyword: '',
      address: '',
      store_name: '',
      county: '',
      district: '',

      floor: '',
      structure: '',
      age: '',
      min_floor_space: '',
      max_floor_space: '',
      min_total_floor_space: '',
      max_total_floor_space: '',
      min_price: '',
      max_price: '',

      // 下拉選單
      // 時間
      date_ranges: dictionary.folders.date_range[0],
      // 類型
      types: dictionary.folders.type[0],
      // 狀態
      statuses: dictionary.folders.status[0],
      // 資料區分
      d_types: dictionary.folders.d_type[0],

    }
  }, mounted() {},
  methods: {}
}
</script>
