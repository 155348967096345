<template>
  <NavBar2 />

  <div>
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages.items[12] }} -->

    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->

  </div>

  <section class="section">
    <div class="container">

      <!-- <a href="{{ route('front.estate-century.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>21物件</h5> -->

      <!--呈現的條件-->
      <small class="filter-text">
        <span v-if="keyword">{{ keyword }},</span>
        <span v-if="address">{{ address }},</span>
        <span v-if="store_name">{{ store_name }},</span>
        <span v-if="county">{{ county }},</span>
        <span v-if="district">{{ district }},</span>
        <span v-if="floor">總樓層{{ floor }},</span>
        <span v-if="structure">格局{{ structure }},</span>
        <span v-if="age">屋齡{{ age }},</span>
        <span v-if="min_floor_space">主建物坪數{{ min_floor_space }}~</span>
        <span v-if="max_floor_space">{{ max_floor_space }}坪,</span>
        <span v-if="min_total_floor_space">總坪數{{ min_total_floor_space }}~</span>
        <span v-if="max_total_floor_space">{{ max_total_floor_space }}坪,</span>
        <span v-if="min_price">總價{{ min_price }}~</span>
        <span v-if="max_price">{{ max_price }}萬,</span>
        <span v-if="date_range">{{ date_range }},</span>
        <span v-if="type">{{ type }},</span>
        <span v-if="status">{{ status }},</span>
        <span v-if="d_type">{{ d_type }},</span>
        <!-- {{ $d_param_key }}: --><br>
      </small>
      <form id="Form" action="">

        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="">
        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" @change="sortByOption" v-model="selectAnswer" name="sort" id="sort">
              <option value="">不限</option>
              <option value="priceH2L">總價高到低排序 </option>
              <option value="priceL2H">總價低到高排序 </option>
              <option value="spaceB2S">坪數大到小排序 </option>
              <option value="spaceS2B">坪數小到大排序 </option>
              <option value="ageH2L">屋齡高到低排序 </option>
              <option value="ageL2H">屋齡低到高排序 </option>
            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto">共<span class="text-danger">{{ Packages.pagination.total
            }}</span>筆資料</small>
          </div>
        </div>

      </form>


      <ul class="estate-list">
        <li v-for="items in Packages.items" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row mb-2">
                <div class="col-auto pr-0">
                  <button class="btn @if($data->is_favorite) btn-primary @endif btn-sm favorite"
                    data-type="estate_century" data-id="">
                    <i data-eva="heart" data-eva-fill="#fff"></i>
                    <i data-eva="heart-outline" data-eva-fill="#C0AC79"></i>
                  </button>
                </div>
                <div class="col">
                  <div class="brand">
                    {{ items.name }}
                  </div>
                </div>
                <div class="col">
                  <small class="date">上架時間：{{ items.post_at }}
                  </small>
                </div>
              </div>
              <div class="estate-item mb-3" data-id="{{items.id}}">
                {{ items.address }}
                <span class="name">
                </span><br>
                <span></span><br>
                <small>建坪{{ items.floor_space }}｜{{ items.structure }}｜{{ items.age }}年｜
                  {{ items.floor }}樓｜{{ items.type
                  }}</small>
              </div>

              <button class="btn btn-primary btn-sm mr-2" type="button">地址還原查詢</button>
              <button class="btn btn-primary btn-sm" type="button">發送物件資訊</button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="">
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price }}</span><small>萬</small>
              </div>
            </div>

          </div>

        </li>

      </ul>

    </div>
  </section>

  <!-- 頁數選單 -->
  <div class="d-flex justify-content-center mb-3">
    <paginate :page-count="20" :page-range="3" :margin-pages="2" :click-handler="clickCallback" :prev-text="'<'"
      :next-text="'>'" :container-class="'pagination'" :page-class="'page-item'">
    </paginate>
  </div>

</template>

<script>
import $ from 'jquery';
import NavBar2 from '@/components/NavBar2.vue';
import { useRoute } from 'vue-router';
// 頁面按鈕
import Paginate from "vuejs-paginate-next";

// 排序引用
import dictionary from '@/assets/data/dictionary.json'

export default {
  name: 'century',
  components: {
    NavBar2,
    paginate: Paginate,
  }, data() {
    const route = useRoute();

    let { keyword, address, store_name, county, district,
      floor, structure, age, min_floor_space, max_floor_space, min_total_floor_space, max_total_floor_space, min_price, max_price, date_range, type, status, d_type
    } = route.query;

    return {
      keyword: keyword,
      address: address,
      store_name: store_name,
      county: county,
      district: district,
      floor: floor,
      structure: structure,
      age: age,
      min_floor_space: min_floor_space,
      max_floor_space: max_floor_space,
      min_total_floor_space: min_total_floor_space,
      max_total_floor_space: max_total_floor_space,
      min_price: min_price,
      max_price: max_price,
      date_range: date_range,
      type: type,
      status: status,
      d_type: d_type,
      Packages: [],

      // 排序
      selectAnswer: '',
      // sortList: dictionary.folders.sort[0],
      // priceAsc:dictionary.folders.sort[1],
      // 坪數
      // floorSpaceDesc:dictionary.folders.sort[2],
      // floorSpaceAsc:dictionary.folders.sort[3],
      //屋齡
    }

  }, mounted() {
    // 這裡寫jQuery
    $(document).ready(function () {
      // 按鈕文字顏色
      $('.pagination').attr('class', 'pagination mb-0');
      $('.page-link').css('border','transparent');
      $('.page-link').css('color','black');
      $('li.page-item').css('color','black');
      $('.page-item.active .page-link').css('border','transparent')

      // 按下一頁後的樣式
      $('.page-item').css('background-color','transparent');



    });

  }, methods: {
    sortByAge() {
      if (this.selectAnswer == 'ageH2L') {
        this.Packages.items.sort((a, b) => b[this.Packages.items.age] > a[this.Packages.items.age] ? 1 : -1)
      } else if (this.selectAnswer == 'ageL2H') {
        this.Packages.items.sort((a, b) => b[this.Packages.items.age] > a[this.Packages.items.age] ? -1 : 1)
      }
    },

    // 頁面按鈕
    clickCallback(pageNum) {
      console.log(pageNum)
    }
  }, computed: {

  }, watch: {
    sortByOption: {
      handler(value) {
        this.sortByAge()
      },
      deep: true
    }
  },
  created() {
    // 搜尋欄的值
    const route = useRoute();

    let { keyword, address, store_name, county, district,
      floor, structure, age, min_floor_space, max_floor_space, min_total_floor_space, max_total_floor_space, min_price, max_price, date_range, type, status, d_type
    } = route.query;

    // 獲取資料
    let url = `http://21centuryedm.ddns.net/api/estate-century/21century?page=1&keyword=${keyword}&address=${address}&store_name=${store_name}&county=${county}&district=${district}&floor=${floor}&structure=${structure}&age=${age}&min_floor_space=${min_floor_space}&max_floor_space=${max_floor_space}&min_total_floor_space=${min_total_floor_space}&max_total_floor_space=${max_total_floor_space}&min_price=${min_price}&max_price=${max_price}&date_range=${date_range}&type=${type}&status=${status}&d_type=${d_type}`
    console.log(url);

    let token = sessionStorage.getItem('token');

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        token: token
      }
    }).then(res => {
      return res.json();
    }).then(formData => {
      this.Packages = formData.data
      console.log('===測試===');
      // console.log(Packages);
      console.log(this.Packages);
      // console.log(this.Packages.items);
      // console.log(formData.data);
      // console.log(formData);
    })
  },
}



</script>

<style lang="css">
/* Adopt bootstrap pagination stylesheet. */
/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */

/* Write your own CSS for pagination */
.page-item{
  background-color: transparent;
  color: black;
}
.page-item.active .page-link{
  background-color: #C0AC79;
  color:black;
  border: 1px solid #C0AC79;
}
.page-item.active{
  background-color: #C0AC79;
  color:black;
}

.page-link{
  background-color: transparent;
  color: black;
  border:transparent;
}
.page-link:hover{
  background-color: transparent;
  color: black;
  border:transparent;
}

</style>
