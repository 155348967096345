<template>

  <Banner />

  <section class="section">
    <div class="container">

      <form method="post" id="Form">

        <div class="form-group">
          <label for="username">帳號 *</label>
          <input type="text" v-model="username" class="form-control" name="username" required maxlength="30"
            placeholder="輸入帳號">
          <!-- {{ username }} -->
        </div>
        <div class="form-group">
          <div class="d-flex">
            <label for="password">密碼 *</label>
            <!--<div class="ml-auto">
            <a href="{{ route('front.forget-pwd.index') }}">忘記密碼</a>
          </div>-->
          </div>
          <input type="password" class="form-control" v-model="password" name="password" required maxlength="30"
            placeholder="輸入密碼">
          <!-- {{ password }} -->
        </div>

        <!-- <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="captcha">驗證碼 *</label>
            <input type="text" class="form-control" name="captcha" id="captcha_text" required maxlength="5" minlength="5">
          </div>
        </div>
        <div class="col-auto d-flex">
          <div class="mt-auto mb-3 mb-md-4">
            <img src="{{ captcha_src() }}" id="captcha" class="img-fluid">
          </div>
        </div>
      </div>-->



        <div class="d-flex justify-content-center align-items-center mt-4">
          <button v-on:click.prevent="login" class="btn btn-primary btn-block">確認</button>
        </div>



      </form>

    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import Banner from '@/components/Banner.vue';

export default {
  name: 'Login',
  components: {
    Banner
  }, data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login: function () {
      // Fetch 登入

      let url = 'http://21centuryedm.ddns.net/api/auth/login';
      let formData = new FormData();
      formData.append('_method', 'post');
      formData.append('csrfToken', 'csrf_token()');

      fetch(url, {
        method: 'post',
        headers: {
          // 'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        // 傳送表單資料
        body: JSON.stringify({
          username: this.username,
          password: this.password
        })

      }).then((res) => {
        return res.json();
      }).then((data) => {

        // 儲存Token到Storage
        let value = data.data.token;
        let putToken = sessionStorage.setItem("token", value);
        let getToken = sessionStorage.getItem("token");

        // 儲存Permission到Storage
        let status = data.data.user_info.login_permission;
        let putPermission = sessionStorage.setItem('permission', status);
        let getPermission = sessionStorage.getItem('permission');

        // 重新導向
        window.location.href = "/"
      })
    }
  }
};


</script>
