<template>
  <NavBar2 />

  <div>
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages.items[2] }} -->


    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->

  </div>

  <section class="section">
    <div class="container">

      <!-- <a href="{{ route('front.estate-competitor.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>同業物件</h5> -->

      <!--呈現的條件-->
      <small class="filter-text">
        <!-- {{ $d_param_key }}: -->{{ $d_param_value }}<br>
        <span v-if="keyword">{{ keyword }}</span>
        <span v-if="county">{{ county }}</span>
        <span v-if="district">{{ district }}</span>
        <span v-if="floor">{{ floor }}</span>
        <span v-if="structure">{{ structure }}</span>
        <span v-if="age">{{ age }}</span>
        <span v-if="brand">{{ brand }}</span>
        <span v-if="max_floor_space">{{ max_floor_space }}</span>
        <span v-if="max_price">{{ max_price }}</span>
        <span v-if="max_total_floor_space">{{ max_total_floor_space }}</span>
        <span v-if="min_floor_space">{{ min_floor_space }}</span>
        <span v-if="min_price">{{ min_price }}</span>
        <span v-if="min_total_floor_space">{{ min_total_floor_space }}</span>
        <span v-if="date_range">{{ date_range }}</span>
        <span v-if="status">{{ status }}</span>
        <span v-if="type">{{ type }}</span>
      </small>
      <form id="Form" action="">

        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="">
        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" name="sort" id="sort">

              <option value="" selected></option>

            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto">共<span class="text-danger">{{ Packages.pagination.total
            }}</span>筆資料</small>
          </div>
        </div>

      </form>


      <ul class="estate-list">
        <li v-for="items in Packages.items" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row mb-2">
                <div class="col-auto pr-0">
                  <button class="btn @if($data->is_favorite) btn-primary @endif btn-sm favorite"
                    data-type="estate_century" data-id="">
                    <i data-eva="heart-outline" data-eva-fill="#C0AC79"></i>
                  </button>
                </div>
                <div class="col">
                  <div class="brand"></div>
                  {{ items.name }}
                </div>
                <div class="col">
                  <small class="date">上架時間：{{ items.post_at }}</small>
                </div>
              </div>

              <div class="estate-item mb-3" data-id="{{ items.id }}">
                {{ items.address }}
                <span class="name"></span><br>
                <span></span><br>
                <small>建坪{{ items.floor_space }}｜{{ items.structure }}｜{{ items.age }}年｜
                  {{ items.floor }}樓｜{{ items.type }}</small>
              </div>

              <button class="btn btn-primary btn-sm mr-2" type="button">地址還原查詢</button>
              <button class="btn btn-primary btn-sm" type="button">發送物件資訊</button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="">
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price }}</span><small>萬</small>
              </div>
            </div>

          </div>

        </li>

      </ul>



    </div>
  </section>

</template>




<script>
import $ from 'jquery';
import NavBar2 from '@/components/NavBar2.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'conpetitor',
  components: {
    NavBar2,
  }, data() {
    const route = useRoute();
    console.log(route.query);

    let { age, brand, county, date_range, district, floor, keyword, max_floor_space, max_price, max_total_floor_space, min_floor_space, min_price, min_total_floor_space, status, structure, type,
    } = route.query;

    return {
      keyword: keyword,
      county: county,
      district: district,
      floor: floor,
      structure: structure,
      age: age,
      brand: brand,
      max_floor_space: max_floor_space,
      max_price: max_price,
      max_total_floor_space: max_total_floor_space,
      min_floor_space: min_floor_space,
      min_price: min_price,
      min_total_floor_space: min_total_floor_space,
      date_range: date_range,
      status: status,
      type: type,
      Packages: [],
    }
  }, mounted() {
    $(document).ready(function () {
      //更換排序
      $("#sort").change(function () {
        $('#Form').submit()
      });

      //點擊
      /*$( ".estate-item" ).click(function() {
        window.location.href = APP_URL+'/estate-competitor/'+$(this).data('id')
    });*/

      // 重新篩選
      $('#back_href').attr('href', APP_URL + '/estate-competitor/filter');
      $('#back_text').text('重新篩選');
    });
  }, created() {
    // 搜尋欄位的值
    const route = useRoute();

    let { age, brand, county, date_range, district, floor, keyword, max_floor_space, max_price, max_total_floor_space, min_floor_space, min_price, min_total_floor_space, status, structure, type
    } = route.query;

    // 獲取資料
    let url = `http://21centuryedm.ddns.net/api/estate-century/competitor?page=1&keyword=${keyword}&county=${county}&district=${district}&date_range=${date_range}&type=${type}&status=${status}&brand=${brand}&floor=${floor}&structure=${structure}&age=${age}&min_floor_space=${min_floor_space}&max_floor_space=${max_floor_space}&min_total_floor_space=${min_total_floor_space}&max_total_floor_space=${max_total_floor_space}&min_price=${min_price}&max_price=${max_price}&brand=${brand}`

    console.log(url);

    let token = sessionStorage.getItem('token');
    console.log(token);

    // Fetch取得資料

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        token: token
      }
    }).then(res => {
      return res.json();
    }).then(formData => {
      this.Packages = formData.data
      console.log('===測試===');
      // console.log(Packages);
      console.log(this.Packages);
      // console.log(formData.data);
      // console.log(formData);
    }
      // {
      //   console.log('===formData===');
      //   console.log(formData);
      //   // 全部資料
      //   console.log(formData.data);

      //   // 資料
      //   console.log(formData.data.items);

      //   // 數量
      //   console.log(formData.data.pagination);



      // }
    )
  }
}


</script>
