<template>

  <NavBar />
  <div id="app">
    <div id="edm" v-cloak>

      <!-- 條件篩選 -->
      <section class="section px-3">
        <div class="container">

          <!-- <a href="{{ route('front.home') }}" class="btn btn-primary">回到選單</a> -->
          <form method="GET" id="Form">

            <div class="form-group ">
              <label>關鍵字 </label>
              <input type="text" class="form-control input-field" name="keyword" placeholder="輸入案名">
            </div>

            <div class="form-group ">
              <label>地址 </label>
              <input type="text" class="form-control input-field" name="address" placeholder="輸入地址">
            </div>

            <div class="form-row" id="twzipcode">
              <div class="col-6">
                <fieldset class="form-group">
                  <label>縣市</label>
                  <div data-role="county" data-name="county" data-value='台北市' data-style="custom-select">
                  </div>
                </fieldset>
              </div>

              <div class="col-6">
                <fieldset class="form-group">
                  <label>行政區</label>
                  <div data-role="district" data-name="district" id="district" data-value='不限'
                    data-style="custom-select">
                  </div>
                </fieldset>
              </div>
              <div data-role="zipcode" data-value="160" data-style="zipcode-style" style="display:none;"></div>

            </div>

            <div class="form-row">
              <div class="col-6">
                <fieldset class="form-group">
                  <label>時間</label>
                  <select class="custom-select" name="date_range">
                  </select>
                </fieldset>
              </div>
              <div class="col-6">
                <fieldset class="form-group">
                  <label>類型</label>
                  <select class="custom-select select-field" name="type">
                    <option value="">不限</option>
                  </select>
                </fieldset>
              </div>
            </div>

            <div class="form-row">
              <div class="col-6">
                <fieldset class="form-group">
                  <label>狀態</label>
                  <select class="custom-select select-field" name="status">
                    <option value="">不限</option>
                  </select>
                </fieldset>
              </div>
              <div class="col-6">
                <fieldset class="form-group">
                  <label>資料區分</label>
                  <select class="custom-select" name="d_type">
                    <option value="">不限</option>
                  </select>
                </fieldset>
              </div>
            </div>

            <div class="form-group ">
              <label>總樓層 </label>
              <input type="text" class="form-control input-field" name="floor" placeholder="總樓層">
            </div>

            <div class="form-group ">
              <label>格局 </label>
              <input type="text" class="form-control input-field" name="structure" placeholder="輸入格局">
            </div>

            <div class="form-group ">
              <label>屋齡 </label>
              <input type="text" class="form-control input-field" name="age" placeholder="輸入屋齡">
            </div>

            <label>主建物坪數</label><br>
            <div class="form-row form-group">

              <div class="col d-flex">
                <input type="number" class="form-control input-field" name="min_floor_space">
                <small class="mt-auto ml-1 text-muted">坪</small>
              </div>
              <div class="col-auto d-flex px-0">
                <div class="my-auto text-muted">～</div>
              </div>
              <div class="col d-flex">
                <input type="number" class="form-control input-field" name="max_floor_space">
                <small class="mt-auto ml-1 text-muted">坪</small>
              </div>
            </div>

            <label>總坪數</label><br>
            <div class="form-row form-group">

              <div class="col d-flex">
                <input type="number" class="form-control input-field" name="min_total_floor_space">
                <small class="mt-auto ml-1 text-muted">坪</small>
              </div>
              <div class="col-auto d-flex px-0">
                <div class="my-auto text-muted">～</div>
              </div>
              <div class="col d-flex">
                <input type="number" class="form-control input-field" name="max_total_floor_space">
                <small class="mt-auto ml-1 text-muted">坪</small>
              </div>
            </div>

            <label>總價</label><br>
            <div class="form-row form-group">

              <div class="col d-flex">
                <input type="number" class="form-control input-field" name="min_price">
                <small class="mt-auto ml-1 text-muted">坪</small>
              </div>
              <div class="col-auto d-flex px-0">
                <div class="my-auto text-muted">～</div>
              </div>
              <div class="col d-flex">
                <input type="number" class="form-control input-field" name="max_price">
                <small class="mt-auto ml-1 text-muted">坪</small>
              </div>
            </div>

            <center class="mt-3 pt-3">
              <button class="btn btn-primary btn-block" type="button">確認</button>
              <button class="btn btn-dark btn-block mt-3" id="clear" type="button">清除</button>
            </center>
          </form>

        </div>
      </section>

      <!-- 物件列表(列表圖) -->
      <section class="section section-cover pt-0 pb-0">
        <img alt="" src="public/imgs/cover.png" class="img-fluid">
      </section>



      <!-- EDM預覽 -->
      <section class="section">
        <div class="container-fluid">
          <p class="text-muted">案件簡介：</p>

          <!-- EDM風格一 -->
          <div class="edm-style-1">
            <div class="image">
              <img alt="" class="cover">
              <div class="logo">
                <img alt="" src="public/imgs/edm/logo.png" class="img-fluid">
              </div>
            </div>
            <div class="header">
              <div class="slogan">我們為你 找到21世紀的家</div>
              <div class="title"></div>
            </div>
            <div class="body">
              <div class="left box">
                <div class="title">案件基本資料</div>
                <ul class="list">
                  <li>
                    <div class="label">地址</div>
                    <!-- @{{ item.county }}@{{ item.district }} -->
                  </li>
                  <li>
                    <div class="label">總坪數</div>
                    <span class="text-danger"></span> 坪
                  </li>
                  <li>
                    <div class="label">格局</div>

                  </li>
                  <li>
                    <div class="label">樓層</div>
                    樓
                  </li>
                  <li>
                    <div class="label">屋齡</div>
                    年
                  </li>
                  <li>
                    <div class="label">種類</div>

                  </li>
                  <li>
                    <div class="label">車位</div>
                  </li>
                </ul>
              </div>
              <div class="right">
                <div class="total-box box">
                  <div class="total">總價 $ <b class="text-danger">@
                      <!--  {{ item.price | commaFormat }}-->
                    </b> 萬</div>
                  <div class="text">
                    <template>$ <!-- @{{ item.price | commaFormat }}-->萬/坪</template>
                    <template>$ <!-- @{{ (item.price / item.floor_space).toFixed(2) | commaFormat
                  }}-->萬/坪</template>
                  </div>
                </div>

                <div class="space"></div>

                <div class="sales-box box">
                  <div>
                    <div class="avatar">
                      <img alt="" src="public/imgs/edm/avatar.jpg?v=20221209">
                    </div>
                  </div>
                  <div class="title">
                    <!-- 業務品牌 -->
                    <div class="job-title"></div>
                    <!-- 業務名稱 -->
                    <div class="name"></div>
                    <div class="name">0921212121</div>
                  </div>
                </div>

                <!-- 業務加盟店名 -->
                <div class="store-title">21世紀不動產 </div>

                <div class="store-box box">
                  <ul class="list">
                    <li>
                      <div class="label">經紀業名稱</div>

                    </li>
                    <li>
                      <div class="label">地址</div>
                      桃園市蘆竹區南崁路一段83號16樓
                    </li>
                    <li>
                      <div class="label">電話</div>

                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div class="footer">
              <div class="title">席捲全球第一房仲品牌</div>
              <div class="count">
                <span class="number">86</span>
                <span class="text">國家</span>
              </div>
              <div class="count">
                <span class="number">16,000</span>
                <span class="text">營業據點</span>
              </div>
              <div class="count">
                <span class="number">169,000</span>
                <span class="text">經紀人</span>
              </div>

            </div>
          </div>

          <center class="mt-3 pt-3">
            <!-- <button class="btn btn-primary btn-block"
            type="button" id="demo">確認製作EDM</button> -->
            <button class="btn btn-dark btn-block" type="button">返回</button>
          </center>
        </div>
      </section>

    </div>
    <input id="token" type="hidden" value="csrf_token()">
  </div>

</template>

<script>
import $ from 'jquery';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'generator',
  components: {
    NavBar,
  },
  mounted() {
    $(document).ready(() => {
      $('#twzipcode').twzipcode();
      const token = $('#token').val();
      // 點擊清除
      $('#clear').click(() => {
        $('.input-field').val('');
        $('.select-field').val('');
        $("[name='district']").val('');
        $("[name='county']").val('台北市');
        $("[name='date_range']").val('三日內');
        $("[name='d_type']").val('租屋');
      });

      // 讀取物件資料
      $.ajax({
        url: "{{ route('front.estate-century.data') }}?page=1",
        type: 'GET',
        data: {
          county: '台北市',
        },
        success(response) {
          HoldOn.close();
          console.log(response);
          data.items = response.data.items;
        },
        beforeSend() {
          // 顯示搜尋動畫
          HoldOn.open({
            theme: 'sk-bounce',
            message: '<h4>Loading...</h4>',
          });
        },
        error(xhr) {
          HoldOn.close();
          console.log(xhr);
          swal('Error', 'Sytem error 系統發生錯誤，請聯繫工程人員', 'error');
        },
      });

      // 新增EDM
      $('#demo').click(() => {
        sendUri = "{{ route('front.edm-generator.store') }}";
        sendData = {
          _token: token, // TOKEN
          template: '11', // 模板代號
          item_ids: '1,2', // 勾選的物件，多個用逗號隔開
        };

        system_ajax(sendUri, sendData, 'POST', (data) => {
          window.location.href = `{{ route('front.edm-generator.result') }}?id=${data.id}`;
        });
      });
    });
  }
}
</script>
