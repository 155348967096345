<template>
  <div class="wrapper">
    <main class="main">
      <!-- banner -->
      <section class="section section-cover pt-0 pb-0">
        <img alt="" src="/imgs/cover.png" class="img-fluid">
      </section>
      <!-- 內容 -->
      <div class="home">
        <section class="section">
          <div class="container">
            <div class="form-row">
              <div class="col-3">
                <router-link to="/estate-competitor/filter" class="icon-text">
                  <img alt="同業物件" src="/imgs/icon_01.png">
                  <span>同業物件</span>
                </router-link>
                <router-view/>
              </div>
              <div class="col-3">
                <router-link to="/estate-fsbo/filter" class="icon-text">
                  <img alt="屋主自售" src="/imgs/icon_02.png">
                  <span>屋主自售</span>
                </router-link>
              </div>
              <div class="col-3">
                <router-link to="/estate-century/filter" class="icon-text">
                  <img alt="21物件icon" src="/imgs/icon_03.png">
                  <span>21物件</span>
                </router-link>
              </div>
              <div class="col-3">
                <router-link to="/edm-generator" class="icon-text">
                  <img alt="製作EDM" src="/imgs/icon_04.png">
                  <span>製作EDM</span>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
