import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// 首頁
import HomePage from '../components/HomePage.vue'

// 四個選項頁面
import CompetitorView from '../views/pages/estate-competitor/filter.vue';
import FsboView from '../views/pages/estate-fsbo/filter.vue';
import CenturyView from '../views/pages/estate-century/filter.vue';
import GeneratorView from '../views/pages/edm-generator/index.vue';

// 三個首頁頁面
import CompetitorIndex from '../views/pages/estate-competitor/index.vue'
import FsboIndex from '../views/pages/estate-fsbo/index.vue'
import CenturyIndex from '../views/pages/estate-century/index.vue'

//登入
import Login from '@/components/Login.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },

  // 搜尋頁面
  {
    path: '/estate-competitor/filter',
    name: 'Competitor',
    component: CompetitorView,
  },
  {
    path: '/estate-fsbo/filter',
    name: 'Fsbo',
    component: FsboView,
  },
  {
    path: '/estate-century/filter',
    name: 'Century',
    component: CenturyView,
  },
  {
    path: '/edm-generator',
    name: 'Generator',
    component: GeneratorView,
  },

  // 搜尋結果頁面
  {
    path: '/estate-competitor/index',
    name: 'CompetitorIndex',
    component: CompetitorIndex,
  }, {
    path: '/estate-fsbo/index',
    name: 'FsboIndex',
    component: FsboIndex,
  }, {
    path: '/estate-century/index',
    name: 'CenturyIndex',
    component: CenturyIndex,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// // 設定路由MiddleWare
router.beforeEach(to => {
  const token = sessionStorage.getItem('token');
  const permission = sessionStorage.getItem('permission');

  // 登入頁（首頁）不用驗證
  if (to.fullPath === '/auth/login') return;

  if (!token || permission == null || permission == 'N') {
    return '/auth/login';
  }

  // 所有頁面Token過期重新登入
  let url = "http://21centuryedm.ddns.net/api/estate-century/21century"
  fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      token: token
    }
  }).then(res => {
    return res.json();
  }).then(formData => {
    // 過期重新登入
    if (formData.status == false) {
      let tokenConfirm = confirm('請重新登入');
      if (tokenConfirm) {
        location.href = '/auth/login'
      } else {
        alert('登入時間已到，請重新登入')
        window.location.reload();
      }
    } else {
      console.log('正常運行中......');
    }
  }
  ).catch((err) => {
    console.log('===Error===');
    console.log(err);
  })


});


export default router;
